<template>
    <div>
        <v-card v-for="report in reports" :key="report.key" class="text-left">
            <v-card-title>
                Report: {{ report.name }}
            </v-card-title>
            <v-card-text>
                <div><small>updated at: {{ report.updated_at }}</small></div>
                <code>{{ report.key }}</code>
                <p>{{ report.description }}</p>
            </v-card-text>
            <v-card-actions>
                <v-btn text :to="{ name: 'manage-pa-report', params: { reportKey: report.key } }">Explore Report</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: 'ProductAnalyticsReportTable',

        props: {
            reports: Array,
        },
    };
</script>

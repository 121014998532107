<template>
    <request :request="request" @on-success="setData">
        <product-analytics-report-table :reports="reports" />
    </request>
</template>

<script>
    import AnalyticsAPI from '../webapi';
    import ProductAnalyticsReportTable from './product-analytics-report-table.vue';

    export default {
        name: 'ProductAnalyticsIndexPage',
        components: {
            ProductAnalyticsReportTable,
        },
        data() {
            return {
                reports: [],
                request: undefined,
            };
        },
        created() {
            this.request = () => AnalyticsAPI.listReports();
        },
        methods: {
            setData(response) {
                // console.warn('set reports', data);
                this.reports = response;
            },
        },
    };
</script>

<template>
    <product-analytics-list-view />
</template>

<script>
    import ProductAnalyticsListView from '@/modules/product-analytics/components/product-analytics-list-view.vue';

    export default {
        name: 'ProductAnalyticsIndexPage',
        components: {
            ProductAnalyticsListView,
        },
        data() {
            return {
                reports: [],
            };
        },
        methods: {
            setReports(data) {
                this.reports = data;
            },
        },
    };
</script>
